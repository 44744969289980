body {
  margin: 0;
  background: black;
  padding: 0;
  font-family: sans-serif;
}

img {
  width: 100%;
}

* {
  box-sizing: border-box;
}

@media (max-width: 650px) {
  .u-hideOnMobile {
    display: none;
  }
}
@media (min-width: 650px) {
  .u-hideOnDesktop {
    display: none;
  }
}
